import React from "react";
import styled from "styled-components";
import Menu from "./menu";
import { ReactComponent as Dotz } from "../assets/Background_dots.svg";
import BillboardImg from "../assets/Bilboard.png";
import media from "./media";
import Particles from "react-particles-js";

const billboard = props => {
  return (
    <div className="">


      <Billboard>
        <div id="particles-js" />
        <Menu scrollToPortfolio={props.scrollToPortfolio} />
        <div className="billboard-content">
          <div className="billboard-text">we invest in difficult problems</div>
          <div className="billboard-img-wrapper">
            <img src={BillboardImg} alt="" className="billboard-img" />
          </div>
        </div>
      </Billboard>
    </div>
  );
};

const Billboard = styled.div`
  height: 1024px;
  background-image: radial-gradient(
    1190px 1190px at 52.29% 89.75%,
    #000986 0%,
    #0092f0 100%
  );
  position: relative;
  ${media.tablet`height: 100vh`};
`;

export default billboard;
