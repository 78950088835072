import React from 'react'
import styled from 'styled-components'
import Menu from './menu'
import media from './media'


const footer = () => {
  return(
    <Footer>
      <Menu />
    </Footer>
  )
}

export default footer


const Footer = styled.div`
  height: 18rem;
  background-image: radial-gradient(1190px 1190px at 52.29% 89.75%, #000986 0%, #0092f0 100%);
  color: white
  bottom: 0;
  position: absolute;
  width: 100%;
  ${media.phone`
    & > * {
      display: flex;
      align-items: flex-start;
      transform: translateY(-7rem);
    }
  `}
`
