import React from 'react'
import styled from 'styled-components'
import media from './media'

import AGLogo from '../assets/AGLogo.png'
import BNLogo from '../assets/BNLogo.png'
import GetInkLogo from '../assets/GetInkLogo.png'
import TLCLogo from '../assets/TLCLogo.png'
import ABNLogo from '../assets/ABNLogo.png'
import StudioMatrixLogo from '../assets/StudioMatrixLogo.png'

const images = [
  AGLogo,
  BNLogo,
  GetInkLogo,
  TLCLogo,
  ABNLogo,
  StudioMatrixLogo
]

const renderImages = () => {
  return images.map(image => {
    return(
      <div className="portfolio-img-wrapper" key={image}>
        <img src={image} alt=""/>
      </div>
    )
  })
}

const portfolio = (props) => {
  return(
    <div className="" ref={props.scrollRef}>
      <Wrapper>
        <Title>
          portfolio
        </Title>
        <div className="portfolio-clients">
          {renderImages()}
        </div>
      </Wrapper>
    </div>
  )
}

export default portfolio


const Wrapper = styled.div`
  padding: 15rem 14rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${media.phone`
    padding: 14rem 3rem;
  `}
`

const Title = styled.div`
  flex: 0 0 100%;
  width: 100%;
  height: 6.3rem;
  color: #0066ce;
  font-family: $fontPrimary;
  font-size: 4.8rem;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: 2.4px;

`
