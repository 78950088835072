import React, { Component } from 'react';
import './App.scss';
import GlobalStyle from './Theme'
import Billboard from './components/Billboard'
import Portfolio from './components/portfolio'
import Footer from './components/footer'
import MobileMenu from './components/MobileMenu'
import scrollToComponent from 'react-scroll-to-component'



class App extends Component {

  scrollToPortfolio = () => {
    scrollToComponent(this.portfolio, {align: 'top'})
  }

  render() {
    return (
      <div className="App">
        <MobileMenu scrollToPortfolio={this.scrollToPortfolio}/>
        <GlobalStyle />
        <Billboard scrollToPortfolio={this.scrollToPortfolio}/>
        <Portfolio scrollRef={(section) => { this.portfolio = section; }}/>
        <Footer />
      </div>
    );
  }
}

export default App;
