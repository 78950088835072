import React from 'react'
import { ReactComponent as Logo } from '../assets/HKLogo.svg';
import GlobalStyle from '../Theme'
import media from './media'

import styled from 'styled-components'

const MenuSection = (props) => {
  return(
  <Menu>
    <GlobalStyle />
    <div className="left-side">
      <div className="icon-box">
        <Logo />
      </div>
    </div>
    <div className="right-side">
      <div className="header-link" onClick={() => props.scrollToPortfolio()}>portfolio</div>
      <a href="mailto:email-us@hamiltonking.com.au" style={{textDecoration: "none"}}>
        <ContactButton>contact</ContactButton>
      </a>
    </div>
  </Menu>
)}




const Menu = styled.div`
  display: flex;
  padding: 9rem 6rem 0 6rem;
  ${media.phone`
    flex-wrap: wrap;
    justify-content: center;
    `}

`
const ContactButton = styled.div`
  font-family: 'GothamBook';
  color: white;
  width: 124px;
  height: 39px;
  border-radius: 14px;
  background: radial-gradient(circle at center, #ffad6f 0%, #ff5f6f 84%, #ff5a6f 100%);
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  letter-spacing: 2.4px;
  margin-left: 6.5rem;
  ${media.phone`
    margin: 0;
    `}
`
export default MenuSection
