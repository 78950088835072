import React, { Component, Suspense } from 'react';
import { slide as Menu } from 'react-burger-menu'

import { ReactComponent as Logo } from '../assets/HKLogo.svg';



export default class MobileMenu extends Component {
  constructor(props){
    super(props)
    this.state = {menuOpen: false}
  }


  closeMenu = () => {
    console.log("closing");
    this.setState({menuOpen: false})
  }

  handleScroll = () => {
    this.closeMenu()
    this.props.scrollToPortfolio()
  }




  render () {
    return (
      <Menu right isOpen={this.state.menuOpen}>
        <Logo className="burger-menu-logo"/>
        <a id="home" className="menu-item" href="/">- Home</a>
        <div className="menu-item" onClick={() => this.handleScroll()}>- Portfolio</div>
        <a id="contact" className="menu-item" href="mailto:email-us@hamiltonking.com.au">- Contact</a>
      </Menu>
    );
  }
}
